import type { TransactionDocument } from '@og-shared/types';
import { getUnitCount, isBankTransaction } from '@og-shared/utils';

export function getTotalSpendingForBudgetFromTransactions(params: {
  transactions: Pick<TransactionDocument, 'budgets' | 'type'>[];
  budgetId: string | undefined;
}) {
  const { transactions = [], budgetId } = params;

  let totalSpend = 0;

  transactions.filter(isBankTransaction).map(transaction => {
    transaction.budgets.map(split => {
      const splitBudgetId = split.child_budget_id
        ? split.child_budget_id
        : split.budget_id;
      if (splitBudgetId !== budgetId) return;
      totalSpend += split.amount;
    });
  });

  return totalSpend;
}

export function getAverageSpend(params: {
  frequency: 1 | 12 | 52 | 365;
  totalSpend: number;
  startDate: string;
  endDate: string;
}) {
  const { startDate, endDate, frequency, totalSpend } = params;
  const unitCount = getUnitCount({ frequency, startDate, endDate });
  const averageSpend = totalSpend / unitCount;
  return { unitCount, averageSpend };
}

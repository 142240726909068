import { AccountDocument } from '@og-shared/types';
import { currencyNumber, exhaustiveCheck } from '@og-shared/utils';

export function getAccountsVsBudget(params: {
  uncategorizedTotal: BudgetsState['transactions']['uncategorized_total'];
  inBudget: BudgetsState['budgets']['budget_totals']['ALL'];
  totalAccountsAvailable: BudgetsState['accounts']['total_accounts_available'];
}) {
  const { uncategorizedTotal, inBudget, totalAccountsAvailable } = params;
  const accountsVsBudget = currencyNumber(
    totalAccountsAvailable - inBudget - uncategorizedTotal
  );
  return accountsVsBudget; // should be zero
}

export function calcTotalAvailable(accounts: AccountDocument[]) {
  let total = 0;
  let checkingTotal = 0;
  let debtTotal = 0;
  accounts.map(account => {
    if (!account.include_in_available) return;
    if (account.type === 'credit' || account.type === 'loan') {
      total += account.current || 0;
      debtTotal += account.current || 0;
    } else {
      total += account.available || 0;
      checkingTotal += account.available || 0;
    }
  });
  return {
    total: currencyNumber(total),
    checkingTotal: currencyNumber(checkingTotal),
    debtTotal: currencyNumber(debtTotal),
  };
}

export function getNetWorthFromAccounts(accounts: AccountDocument[]) {
  let liabilitiesTotal = 0;
  let assetsTotal = 0;
  const assets: AccountDocument[] = [];
  const liabilities: AccountDocument[] = [];
  accounts.map(account => {
    const type = account.type;
    switch (type) {
      case 'credit': {
        liabilitiesTotal += account.current || 0;
        liabilities.push(account);
        return;
      }
      case 'loan': {
        liabilitiesTotal += account.current || 0;
        liabilities.push(account);
        return;
      }
      case 'brokerage': {
        assetsTotal += account.available;
        assets.push(account);
        return;
      }
      case 'depository': {
        assetsTotal += account.available;
        assets.push(account);
        return;
      }
      case 'investment': {
        assetsTotal += account.available;
        assets.push(account);
        return;
      }
      case 'other': {
        assetsTotal += account.available;
        assets.push(account);
        return;
      }
      case 'property': {
        assetsTotal += account.available;
        assets.push(account);
        return;
      }
      default: {
        exhaustiveCheck(type);
      }
    }
  });
  return {
    liabilitiesTotal: currencyNumber(liabilitiesTotal),
    assetsTotal: currencyNumber(assetsTotal),
    netWorth: currencyNumber(assetsTotal + liabilitiesTotal),
    assets,
    liabilities,
  };
}

export function getAccountName(account: AccountDocument | undefined) {
  if (!account) return '';
  return account.name_custom || account.name;
}

import { DataPoint } from '@og-shared/types';
import { ChartDataset, ScatterDataPoint } from 'chart.js';

export function getHorizontalLineDataset(params: {
  label?: string;
  color: string;
  data: DataPoint[];
}) {
  const { label, data, color } = params;
  const dataset: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    pointRadius: 0,
    segment: {
      borderWidth: 1,
      borderDash: [20, 5],
    },
  };
  return dataset;
}
